import { useTranslation } from "react-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import {
  BookOpenIcon,
  ChartSquareBarIcon,
  ChatIcon,
  ChevronDownIcon,
  ClipboardListIcon,
  CogIcon,
  HomeIcon,
  KeyIcon,
  PlusIcon,
  ShareIcon,
  SpeakerphoneIcon,
  TagIcon,
  TerminalIcon,
  UserCircleIcon,
  UserGroupIcon,
  ViewGridAddIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import PermissionsChecker from "src/components/PermissionsCheck";
import InboxIconWithIndicator from "../components/InboxIconWithIndicator";
import { buildRoute } from "../router/router";
import NavigationItem from "./NavigationItem";

export const navigation = [
  {
    // i18n.t("navigation:home.title", "Home")
    name: "navigation:home.title",
    href: "/",
    icon: <HomeIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [],
  },
  {
    // i18n.t("navigation:contacts.title", "Contacts")
    name: "navigation:contacts.title",
    href: buildRoute("contact-list"),
    icon: <UserGroupIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    permission: "view_contact",
    children: [],
  },
  {
    // i18n.t("navigation:templates.title", "WA Templates")
    name: "navigation:templates.title",
    href: buildRoute("whatsapp-template-list"),
    icon: <ClipboardListIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [],
  },
  {
    // i18n.t("navigation:inbox.title", "Inbox")
    name: "navigation:inbox.title",
    href: "/inbox",
    icon: <InboxIconWithIndicator />,
    children: [],
    isMatchEnd: false,
    permission: "view_inboxcontact",
  },
  {
    // i18n.t("navigation:broadcasts.title", "Broadcasts")
    name: "navigation:broadcasts.title",
    href: buildRoute("broadcast-list"),
    icon: <ChatIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [],
    permission: "view_messageblast",
  },
  {
    // i18n.t("navigation:flows.title", "Flows")
    name: "navigation:flows.title",
    href: buildRoute("flow-list"),
    icon: <ShareIcon className="mr-2 h-5 w-5 rotate-90" />,
    children: [],
    permission: "view_flow",
  },
  {
    // i18n.t("navigation:settings.title", "Settings")
    name: "navigation:settings.title",
    href: buildRoute("config-account"),
    icon: <CogIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [],
    permission: "change_account",
  },
  {
    // i18n.t("navigation:reports.title", "Reports")
    name: "navigation:reports.title",
    href: buildRoute("reports"),
    icon: <ChartSquareBarIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [
      {
        // i18n.t("navigation:reports.summary.title", "Overview")
        name: "navigation:reports.summary.title",
        href: buildRoute("reports", { ":type": "summary" }),
        icon: (
          <ChartSquareBarIcon className="mr-2 h-4 w-4" aria-hidden="true" />
        ),
      },
      {
        // i18n.t("navigation:reports.by-agent.title", "By Agent")
        name: "navigation:reports.by-agent.title",
        href: buildRoute("reports", { ":type": "by-agent" }),
        icon: <UserCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:reports.by-tag.title", "By Tag")
        name: "navigation:reports.by-tag.title",
        href: buildRoute("reports", { ":type": "by-tag" }),
        icon: <TagIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:reports.detail.title", "Details")
        name: "navigation:reports.detail.title",
        href: buildRoute("reports", { ":type": "detail" }),
        icon: <ViewListIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
    ],
  },
  {
    // i18n.t("navigation:integrations.title", "Integrations")
    name: "navigation:integrations.title",
    href: "#",
    icon: <ViewGridAddIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    children: [
      {
        // i18n.t("navigation:integrations.api-keys.title", "API Keys")
        name: "navigation:integrations.api-keys.title",
        href: buildRoute("api-keys"),
        icon: <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:integrations.external-apps.title", "External Apps")
        name: "navigation:integrations.external-apps.title",
        href: buildRoute("external-apps"),
        icon: <PlusIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:integrations.webhooks.title", "Webooks")
        name: "navigation:integrations.webhooks.title",
        href: buildRoute("webhook-list"),
        icon: <TerminalIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:integrations.dev-monitoring.title", "Dev Monitoring")
        name: "navigation:integrations.dev-monitoring.title",
        href: buildRoute("dev-monitoring", { ":tab": "webhook-logs" }),
        icon: <SpeakerphoneIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
      {
        // i18n.t("navigation:integrations.api-docs.title", "API Docs")
        name: "navigation:integrations.api-docs.title",
        href: "https://hilos.io/docs/apidocs",
        target: "_blank",
        icon: <BookOpenIcon className="mr-2 h-4 w-4" aria-hidden="true" />,
      },
    ],
  },
];

function Navigation({ onCloseMenu }) {
  const { t } = useTranslation();

  return (
    <>
      {navigation.map((item) => (
        <PermissionsChecker permission={item.permission} showNotice={false}>
          {item.children.length === 0 ? (
            <NavigationItem
              {...item}
              key={item.name}
              name={t(item.name)}
              onCloseMenu={onCloseMenu}
            />
          ) : (
            <Disclosure key={item.name}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`${
                      open
                        ? "bg-gray-100 text-gray-600"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:hover:bg-gray-50"
                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm font-medium`}
                  >
                    <span
                      className={`${
                        open
                          ? "text-gray-600"
                          : "text-gray-400 group-hover:text-gray-500"
                      } flex flex-shrink-0 items-center`}
                    >
                      {item.icon}
                      {t(item.name)}
                    </span>

                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-4 w-4`}
                    />
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      {item.children.map((child) => (
                        <div className="ml-2">
                          <NavigationItem
                            isChild
                            {...child}
                            key={child.name}
                            name={t(child.name)}
                            onCloseMenu={onCloseMenu}
                          />
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          )}
        </PermissionsChecker>
      ))}
    </>
  );
}

export default Navigation;
